import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile,
        loggedUserInformation:
          state?.loggedUserInformation === null
            ? action.userProfile
            : state?.loggedUserInformation,
      };
    case types.LOAD_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case types.LOAD_USER_TRAINING_PACE_SUCCESS:
      return {
        ...state,
        trainingPace: action.userShapeInfo,
      };
    case types.LOAD_USER_TRAINING_INFORMATION_SUCCESS:
      return {
        ...state,
        userTrainingInformation: action.userTrainingInformation,
      };
    case types.LOAD_USER_PERSONAL_BEST_SUCCESS:
      return {
        ...state,
        userPersonalBest: action.userPersonalBest,
      };
    case types.LOAD_COACH_USERS_SUCCESS:
      return {
        ...state,
        coachUsers: action.users,
      };
    case types.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile,
      };
    case types.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case types.UPDATE_USER_TRAINING_INFO_SUCCESS:
      console.log(action);
      return {
        ...state,
        userTrainingInformation: action.userTrainingInfo,
      };
    case types.UPDATE_USER_PERSONAL_BEST_SUCCESS:
      return {
        ...state,
        userPersonalBest: action.userPersonalBest,
      };
    case types.SET_USER:
      return {
        ...state,
        selectedUser: action.user,
      };
    case types.LOGOUT:
      return {
        state,
      };
    default:
      return state;
  }
}
