import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/api/v1/user";

export function getUserProfile(userId) {
  return fetch(baseUrl + "/" + userId, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserDetails(userId) {
  return fetch(baseUrl + "/details/" + userId, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserTrainingInfromation(userId) {
  return fetch(baseUrl + "/trainigInformation/" + userId, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserPersonalBest(userId) {
  return fetch(baseUrl + "/personalBest/" + userId, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUserProfile(user) {
  return fetch(baseUrl + "/profile", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUserDetails(userDetails) {
  return fetch(baseUrl + "/details", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(userDetails),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUserTrainingInfo(userTrainingInfo) {
  console.log(userTrainingInfo);
  return fetch(baseUrl + "/trainingInfo", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(userTrainingInfo),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUserPersonalBest(userPersonalBest) {
  return fetch(baseUrl + "/personalBest", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(userPersonalBest),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCoachUsers(coachId) {
  return fetch(baseUrl + "/coachUsers/" + coachId, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserTrainingPace(vdot, seasonIndex) {
  return fetch(baseUrl + `/trainingPace/${vdot}/${seasonIndex}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then(handleResponse)
    .catch(handleError);
}
