import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./paceLegendStyles.css";
import { useSelector } from "react-redux";

function PaceLegend() {
  const paceObject = useSelector((store) => store.user.trainingPace);
  return (
    <Container fluid>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <span className="dot bc1"></span>
              <span className="pace-description">
                <strong>BC I</strong>: {paceObject.bci}
              </span>
            </Col>
            <Col>
              <span className="dot bc2"></span>
              <span className="pace-description">
                <strong>BC II</strong>: {paceObject.bcii}
              </span>
            </Col>
            <Col>
              <span className="dot threshold"></span>
              <span className="pace-description">
                <strong>Próg</strong>: {paceObject.threshold1000}
              </span>
            </Col>
            <Col>
              <span className="dot vo2max"></span>
              <span className="pace-description">
                <strong>VO2max</strong>: {paceObject.interval1000}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default PaceLegend;
