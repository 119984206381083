import * as types from "./actionTypes";
import * as userApi from "../../api/userApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadUserProfileSuccess(userProfile) {
  return { type: types.LOAD_USER_PROFILE_SUCCESS, userProfile };
}

export function loadUserDetailsSuccess(userDetails) {
  return { type: types.LOAD_USER_DETAILS_SUCCESS, userDetails };
}

export function loadUserTrainingInformationSuccess(userTrainingInformation) {
  return {
    type: types.LOAD_USER_TRAINING_INFORMATION_SUCCESS,
    userTrainingInformation,
  };
}

export function loadUserPersonalBestSuccess(userPersonalBest) {
  return { type: types.LOAD_USER_PERSONAL_BEST_SUCCESS, userPersonalBest };
}

export function loadCoachUsersSuccess(users) {
  return { type: types.LOAD_COACH_USERS_SUCCESS, users };
}

export function updateUserProfileSuccess(userProfile) {
  return { type: types.UPDATE_USER_PROFILE_SUCCESS, userProfile };
}

export function updateUserDetailsSuccess(userDetails) {
  return { type: types.UPDATE_USER_DETAILS_SUCCESS, userDetails };
}

export function updateUserTrainingInfoSuccess(userTrainingInfo) {
  return { type: types.UPDATE_USER_TRAINING_INFO_SUCCESS, userTrainingInfo };
}

export function updateUserPersonalBestSuccess(userPersonalBest) {
  return { type: types.UPDATE_USER_PERSONAL_BEST_SUCCESS, userPersonalBest };
}

export function setUser(user) {
  return { type: types.SET_USER, user };
}

export function loadUserTrainingPaceSuccess(userShapeInfo) {
  return { type: types.LOAD_USER_TRAINING_PACE_SUCCESS, userShapeInfo };
}

export function loadUserProfile(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserProfile(userId)
      .then((userProfile) => {
        dispatch(loadUserProfileSuccess(userProfile));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserDetails(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserDetails(userId)
      .then((userDetails) => {
        dispatch(loadUserDetailsSuccess(userDetails));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserTrainingInformation(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserTrainingInfromation(userId)
      .then((userTrainingInformation) => {
        dispatch(loadUserTrainingInformationSuccess(userTrainingInformation));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserPersonalBest(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserPersonalBest(userId)
      .then((userPersonalBest) => {
        dispatch(loadUserPersonalBestSuccess(userPersonalBest));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadCoachUsers(coachId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getCoachUsers(coachId)
      .then((users) => {
        dispatch(loadCoachUsersSuccess(users));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updateUserProfile(userProfile) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .updateUserProfile(userProfile)
      .then((updatedProfile) => {
        dispatch(updateUserProfileSuccess(updatedProfile));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updateUserDetails(userDetails) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .updateUserDetails(userDetails)
      .then((updatedUserDetails) => {
        dispatch(updateUserDetailsSuccess(updatedUserDetails));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updateUserTrainingInfo(userTrainingInfo) {
  return function (dispatch) {
    console.log(userTrainingInfo);
    dispatch(beginApiCall());
    return userApi
      .updateUserTrainingInfo(userTrainingInfo)
      .then((updatedUserTrainingInfo) => {
        dispatch(updateUserTrainingInfoSuccess(updatedUserTrainingInfo));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updateUserPersonalBest(userPersonalBest) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .updateUserPersonalBest(userPersonalBest)
      .then((updatedUserPersonalBest) => {
        dispatch(updateUserPersonalBestSuccess(updatedUserPersonalBest));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserTrainingPace(userShapeInfo) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserTrainingPace(userShapeInfo.vdot, userShapeInfo.seasonIndex)
      .then((trainingPace) => {
        dispatch(loadUserTrainingPaceSuccess(trainingPace));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
